import FormError from '../FormError'
import { ChangeEvent, FocusEventHandler, forwardRef, HTMLInputTypeAttribute } from 'react'

type InputProps = {
  disabled?: boolean
  label?: string
  name?: string
  onBlur?: FocusEventHandler
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onClick?: () => void
  isReadOnly?: boolean
  placeholder?: string
  required?: boolean
  type?: HTMLInputTypeAttribute
  value?: string
  width?: 'max-w-xs' | 'max-w-sm' | 'max-w-md' | 'max-w-lg' | 'max-w-full'
  error?: string
  maxLength?: number
  step?: number
  min?: number | string
  max?: number | string
  showOptionalLabel?: boolean
}

const Input = forwardRef<HTMLInputElement, InputProps>(function Input({
  disabled = false,
  label,
  name,
  onBlur,
  onChange,
  onClick,
  isReadOnly,
  required = false,
  placeholder,
  type = 'text',
  value,
  width = 'full',
  error,
  maxLength,
  step,
  min,
  max,
  showOptionalLabel = false,
}, ref) {

  return (
    <div className={`w-full ${width} relative`}>
      {
        label !== undefined &&
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {label}{showOptionalLabel && <span className="text-xs text-placeholder ml-1"> Optional</span>}
        </label>
      }
      <div className="mt-1">
        <input
          ref={ref}
          step={step}
          readOnly={isReadOnly}
          disabled={disabled}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onClick={onClick}
          required={required}
          placeholder={placeholder}
          type={type}
          value={value}
          maxLength={maxLength}
          max={max}
          min={min}
          className="block w-full disabled:bg-gray-200 appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-blue focus:outline-none focus:ring-primary-blue sm:text-sm"
        />
      </div>
      <div>
        {
          error &&
          <FormError text={error} />
        }
      </div>
    </div>
  )
})


export default Input