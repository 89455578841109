import { ReactNode } from 'react'

const ModalContent = ({ children, className }: { children: ReactNode, className?: string }) => {
  return (
    <div className={className}>
      {children}
    </div>
  )
}

export default ModalContent
