const Logo = ({ className }: { className: string }) => {
  return (
    <svg className={className} viewBox="0 0 862 117" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1100_3309)">
        <path d="M42.88 63.32V47.27H74.98V82.54C70.29 86.52 64.9 89.39 58.81 91.15C52.72 92.91 46.66 93.79 40.65 93.79C32.21 93.79 24.95 92.11 18.86 88.75C12.77 85.39 8.1 80.65 4.86 74.52C1.62 68.38 0 61.09 0 52.66C0 44.69 1.72 37.62 5.16 31.45C8.6 25.28 13.38 20.44 19.51 16.92C25.64 13.41 32.69 11.65 40.66 11.65C47.14 11.65 53.21 12.72 58.88 14.87C64.54 17.02 69.29 20.59 73.11 25.59L59.64 39.18C57.22 36.76 54.62 34.77 51.85 33.2C49.08 31.64 45.85 30.86 42.18 30.86C37.73 30.86 33.94 31.86 30.81 33.85C27.69 35.84 25.32 38.5 23.72 41.82C22.12 45.14 21.32 48.87 21.32 53.01C21.32 59.65 23.23 64.98 27.06 69C30.89 73.02 35.96 75.03 42.29 75.03C44.87 75.03 47.33 74.7 49.67 74.03C52.01 73.37 54.28 72.29 56.46 70.81V63.31H42.87L42.88 63.32Z" fill="#002DA6" />
        <path d="M84.1201 64.37C84.1201 58.9 85.3501 53.94 87.8101 49.49C90.2701 45.04 93.7301 41.5 98.1801 38.89C102.63 36.27 107.75 34.97 113.53 34.97C119.31 34.97 124.52 36.28 128.94 38.89C133.35 41.51 136.79 45.04 139.25 49.49C141.71 53.94 142.94 58.9 142.94 64.37C142.94 69.84 141.71 74.8 139.25 79.25C136.79 83.7 133.35 87.24 128.94 89.85C124.53 92.47 119.39 93.77 113.53 93.77C107.67 93.77 102.63 92.46 98.1801 89.85C93.7301 87.24 90.2701 83.7 87.8101 79.25C85.3501 74.8 84.1201 69.84 84.1201 64.37ZM113.53 76.44C116.81 76.44 119.43 75.29 121.38 72.98C123.33 70.68 124.31 67.81 124.31 64.37C124.31 60.93 123.33 58.06 121.38 55.76C119.43 53.46 116.81 52.3 113.53 52.3C110.25 52.3 107.63 53.45 105.68 55.76C103.73 58.06 102.75 60.94 102.75 64.37C102.75 67.8 103.73 70.68 105.68 72.98C107.63 75.29 110.25 76.44 113.53 76.44Z" fill="#002DA6" />
        <path d="M196.36 91.91L168.01 13.76H190.62L208.19 68.12L224.59 13.76H246.73L218.26 91.91H196.35H196.36Z" fill="#002DA6" />
        <path d="M250.61 64.37C250.61 58.9 251.84 53.94 254.3 49.49C256.76 45.04 260.22 41.5 264.67 38.89C269.12 36.27 274.24 34.97 280.02 34.97C285.8 34.97 291.01 36.28 295.43 38.89C299.84 41.51 303.28 45.04 305.74 49.49C308.2 53.94 309.43 58.9 309.43 64.37C309.43 69.84 308.2 74.8 305.74 79.25C303.28 83.7 299.84 87.24 295.43 89.85C291.02 92.47 285.88 93.77 280.02 93.77C274.16 93.77 269.12 92.46 264.67 89.85C260.22 87.24 256.76 83.7 254.3 79.25C251.84 74.8 250.61 69.84 250.61 64.37ZM280.02 76.44C283.3 76.44 285.92 75.29 287.87 72.98C289.82 70.68 290.8 67.81 290.8 64.37C290.8 60.93 289.82 58.06 287.87 55.76C285.92 53.46 283.3 52.3 280.02 52.3C276.74 52.3 274.12 53.45 272.17 55.76C270.22 58.06 269.24 60.94 269.24 64.37C269.24 67.8 270.22 70.68 272.17 72.98C274.12 75.29 276.74 76.44 280.02 76.44Z" fill="#002DA6" />
        <path d="M318.56 91.91V13.76H338.36V91.91H318.56Z" fill="#002DA6" />
        <path d="M349.61 71.64V36.84H369.41V66.95C369.41 69.61 369.98 71.77 371.11 73.45C372.24 75.13 374.25 75.97 377.14 75.97C380.26 75.97 382.43 74.96 383.64 72.92C384.85 70.89 385.46 68.7 385.46 66.36V36.84H405.26V91.91H386.16L385.46 86.29C384.05 88.09 382.36 89.51 380.36 90.57C378.37 91.62 376.4 92.37 374.44 92.8C372.49 93.23 370.85 93.44 369.52 93.44C363.82 93.44 359.07 91.74 355.29 88.34C351.5 84.94 349.61 79.38 349.61 71.64V71.64Z" fill="#002DA6" />
        <path d="M416.74 91.9101V36.8401H435.84L436.54 42.5801C438.02 40.7801 439.74 39.3601 441.69 38.3001C443.64 37.2501 445.59 36.5001 447.55 36.0701C449.5 35.6401 451.14 35.4301 452.47 35.4301C456.3 35.4301 459.73 36.1701 462.78 37.6601C465.83 39.1401 468.23 41.4901 469.99 44.6901C471.75 47.8901 472.63 52.0701 472.63 57.2301V91.9101H452.83V61.9201C452.83 59.1901 452.26 57.0001 451.13 55.3601C450 53.7201 448.18 52.9001 445.68 52.9001C442.16 52.9001 439.76 53.9201 438.47 55.9501C437.18 57.9801 436.54 60.1701 436.54 62.5101V91.9201H416.74V91.9101Z" fill="#002DA6" />
        <path d="M487.39 68.7101V52.0701H479.31V36.8401H487.39V18.6801H507.19V36.8401H520.78V52.0701H507.19V71.5201C507.19 76.0501 509.34 78.3201 513.63 78.3201C514.88 78.3201 516.11 78.0501 517.32 77.5001C518.53 76.9501 519.64 76.3301 520.66 75.6301L528.39 87.8201C525.89 89.7701 523.12 91.3001 520.07 92.3901C517.02 93.4801 513.9 94.0301 510.7 94.0301C504.76 94.0301 500.1 92.9401 496.7 90.7501C493.3 88.5601 490.9 85.5801 489.49 81.7901C488.08 78.0001 487.38 73.6501 487.38 68.7301L487.39 68.7101Z" fill="#002DA6" />
        <path d="M580.54 75.04L586.75 87.93C584.95 88.95 582.06 90.18 578.08 91.62C574.1 93.06 569.33 93.79 563.79 93.79C553.71 93.79 545.96 91.23 540.53 86.12C535.1 81 532.39 73.84 532.39 64.62C532.39 60.95 532.97 57.36 534.15 53.84C535.32 50.32 537.1 47.14 539.48 44.29C541.86 41.44 544.87 39.18 548.5 37.49C552.13 35.81 556.37 34.97 561.21 34.97C566.05 34.97 570.88 36.12 574.98 38.43C579.08 40.74 582.34 44.21 584.76 48.86C587.18 53.51 588.39 59.39 588.39 66.49V70.24H551.13C551.21 71.49 551.66 72.8 552.48 74.17C553.3 75.54 554.71 76.69 556.7 77.63C558.69 78.57 561.4 79.04 564.84 79.04C568.28 79.04 571.28 78.61 574.1 77.75C576.91 76.89 579.06 75.99 580.54 75.06V75.04ZM551.25 58.52H570.82C570.82 57.27 570.47 55.98 569.77 54.65C569.07 53.32 567.99 52.17 566.55 51.19C565.1 50.21 563.25 49.73 560.98 49.73C558.71 49.73 556.98 50.22 555.53 51.19C554.08 52.17 553.01 53.32 552.31 54.65C551.61 55.98 551.26 57.27 551.26 58.52H551.25Z" fill="#002DA6" />
        <path d="M642.98 75.04L649.19 87.93C647.39 88.95 644.5 90.18 640.52 91.62C636.54 93.06 631.77 93.79 626.23 93.79C616.15 93.79 608.4 91.23 602.97 86.12C597.54 81 594.83 73.84 594.83 64.62C594.83 60.95 595.42 57.36 596.59 53.84C597.76 50.32 599.54 47.14 601.92 44.29C604.3 41.44 607.31 39.18 610.94 37.49C614.57 35.81 618.81 34.97 623.65 34.97C628.49 34.97 633.32 36.12 637.42 38.43C641.52 40.74 644.78 44.21 647.2 48.86C649.62 53.51 650.83 59.39 650.83 66.49V70.24H613.57C613.65 71.49 614.1 72.8 614.92 74.17C615.74 75.54 617.15 76.69 619.14 77.63C621.13 78.57 623.85 79.04 627.28 79.04C630.71 79.04 633.72 78.61 636.54 77.75C639.35 76.89 641.5 75.99 642.98 75.06V75.04ZM613.69 58.52H633.26C633.26 57.27 632.91 55.98 632.21 54.65C631.51 53.32 630.43 52.17 628.99 51.19C627.54 50.21 625.69 49.73 623.43 49.73C621.17 49.73 619.43 50.22 617.98 51.19C616.53 52.17 615.46 53.32 614.76 54.65C614.06 55.98 613.71 57.27 613.71 58.52H613.69Z" fill="#002DA6" />
        <path d="M659.62 91.91V36.84H678.72L679.42 43.75C680.98 41.56 682.91 39.71 685.22 38.19C687.52 36.67 690.32 35.91 693.6 35.91C695.16 35.91 696.51 35.99 697.64 36.14C698.77 36.3 699.77 36.53 700.63 36.84L698.05 53.71C696.17 53.24 694.26 53.01 692.31 53.01C683.72 53.01 679.42 57.74 679.42 67.19V91.91H659.62Z" fill="#002DA6" />
        <path d="M706.48 29.34V13.76H727.92V29.34H706.48ZM707.3 91.9V36.84H727.1V91.91H707.3V91.9Z" fill="#002DA6" />
        <path d="M738.93 91.9101V36.8401H758.03L758.73 42.5801C760.21 40.7801 761.93 39.3601 763.89 38.3001C765.84 37.2501 767.79 36.5001 769.75 36.0701C771.7 35.6401 773.34 35.4301 774.67 35.4301C778.5 35.4301 781.93 36.1701 784.98 37.6601C788.03 39.1401 790.43 41.4901 792.19 44.6901C793.95 47.8901 794.83 52.0701 794.83 57.2301V91.9101H775.03V61.9201C775.03 59.1901 774.46 57.0001 773.33 55.3601C772.2 53.7201 770.38 52.9001 767.88 52.9001C764.37 52.9001 761.96 53.9201 760.67 55.9501C759.38 57.9801 758.74 60.1701 758.74 62.5101V91.9201H738.94L738.93 91.9101Z" fill="#002DA6" />
        <path d="M807.711 110.77L815.211 97.06C817.321 98.31 819.561 99.32 821.951 100.11C824.331 100.89 826.771 101.28 829.271 101.28C833.331 101.28 836.36 100.36 838.35 98.53C840.34 96.69 841.341 93.63 841.341 89.33V87.46C839.621 89.1 837.57 90.15 835.19 90.62C832.81 91.09 830.48 91.32 828.22 91.32C820.41 91.32 814.37 88.92 810.12 84.11C805.86 79.31 803.73 73.12 803.73 65.54C803.73 59.14 805.101 53.71 807.831 49.25C810.561 44.8 814.351 41.42 819.201 39.12C824.041 36.82 829.591 35.66 835.841 35.66C840.211 35.66 844.49 36.05 848.67 36.83C852.85 37.61 856.971 38.75 861.031 40.23V87.45C861.031 97.92 858.49 105.41 853.41 109.95C848.33 114.48 840.911 116.75 831.151 116.75C827.091 116.75 823.051 116.32 819.031 115.46C815.011 114.6 811.24 113.04 807.72 110.77H807.711ZM841.451 66.25V52.78C839.341 52.08 837.23 51.73 835.12 51.73C831.06 51.73 828.111 53 826.271 55.54C824.431 58.08 823.521 61.18 823.521 64.85C823.521 68.52 824.4 71.16 826.16 72.99C827.92 74.83 830.121 75.74 832.781 75.74C835.441 75.74 837.45 74.98 839.05 73.46C840.65 71.94 841.451 69.54 841.451 66.25V66.25Z" fill="#002DA6" />
        <path d="M82.7797 16.8401C84.5597 20.6101 86.5097 24.3001 88.6297 27.8901C89.3997 29.1901 90.7597 30.2501 92.2197 30.6501C93.6797 31.0501 95.5697 30.8701 96.8397 30.0501C98.1097 29.2301 99.2497 27.9901 99.5997 26.4601C99.9497 24.9301 99.8297 23.2501 98.9997 21.8401C96.8797 18.2501 94.9297 14.5601 93.1497 10.7901C92.5397 9.50006 90.8897 8.40006 89.5597 8.03006C88.1197 7.63006 86.2097 7.81006 84.9397 8.63006C83.6697 9.45006 82.5297 10.6901 82.1797 12.2201C82.1097 12.7501 82.0397 13.2801 81.9697 13.8201C81.9697 14.9001 82.2497 15.9101 82.7897 16.8501L82.7797 16.8401Z" fill="#00C79B" />
        <path d="M108.95 6.00002C108.95 10.86 108.97 15.73 108.98 20.59C108.98 22.13 109.65 23.74 110.74 24.83C111.83 25.92 113.49 26.65 114.98 26.59C118.23 26.44 120.99 23.95 120.98 20.59C120.98 15.73 120.96 10.86 120.95 6.00002C120.95 4.46002 120.28 2.85002 119.19 1.76002C118.1 0.670024 116.44 -0.0599763 114.95 2.36735e-05C111.7 0.150024 108.94 2.64002 108.95 6.00002Z" fill="#00C79B" />
        <path d="M137.87 30.25C141.96 26.18 145.61 21.6 148.7 16.73C149.52 15.45 149.7 13.55 149.3 12.11C148.9 10.67 147.84 9.21004 146.54 8.52004C143.57 6.95004 140.13 7.84004 138.33 10.67C137.17 12.49 135.94 14.25 134.62 15.96L135.56 14.75C133.65 17.22 131.58 19.56 129.37 21.76C128.28 22.84 127.61 24.47 127.61 26C127.61 27.53 128.26 29.23 129.37 30.24C130.48 31.25 132.01 32.07 133.61 32C135.21 31.93 136.7 31.39 137.85 30.24L137.87 30.25Z" fill="#00C79B" />
      </g>
      <defs>
        <clipPath id="clip0_1100_3309">
          <rect width="861.02" height="116.75" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Logo