import { gql } from '@apollo/client'
import { OPPORTUNITY_FIELDS, USER_FIELDS } from '../fragments'

export const GET_AUTH_USER = gql`
  ${USER_FIELDS}
  query {
    getAuthUser {
      ...UserFields
    }
  }
`

export const GET_AUTH_VOLUNTER_MANAGER_USER = gql`
  ${USER_FIELDS}
  query {
    getAuthUser {
      ...UserFields
      canCreateOpportunities
      organisation {
        subscription {
            type
            status
            priceInformation {
                monthlyTotal
                seatPrice
            }
        }
        subscriptionLimits {
          opportunityLimit
          volunteerLimit
          opportunityCount
          volunteerCount
        }
      }
    }
  }
`

export const GET_USER_OPPORTUNITIES = gql`
  ${OPPORTUNITY_FIELDS}
  query (
    $opportunitiesFirst: Int = 3, 
    $opportunitiesPage: Int, 
    $opportunitiesFilter: OpportunitiesFilter
  ) {
    getAuthUser {
      id
      opportunities (first: $opportunitiesFirst, page: $opportunitiesPage, opportunitiesFilter: $opportunitiesFilter) {
        data {
          ...OpportunityFields
        }
        paginatorInfo {
          currentPage
          lastPage
          total
        }
      }
    }
  }
`