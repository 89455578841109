import { ChangeEventHandler, FocusEventHandler, forwardRef } from 'react'

type PropTypes = {
  value: string
  name: string
  disabled?: boolean
  checked?: boolean
  onBlur?: FocusEventHandler,
  onChange?: ChangeEventHandler<HTMLInputElement>,
  label: string
  id: string
}

const RadioButton = forwardRef<HTMLInputElement, PropTypes>(function RadioButton ({
  value,
  name,
  disabled = false,
  checked = false,
  onBlur,
  onChange,
  label,
  id,
}, ref) {
  return (
    <div className="flex items-center">
      <input
        id={id}
        defaultChecked={checked}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        type="radio"
        value={value}
        ref={ref}
        disabled={disabled}
        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500 disabled:cursor-not-allowed peer"
      />
      <label htmlFor={id} className="ml-3 block text-sm font-medium text-gray-700 peer-disabled:cursor-not-allowed">
        {label}
      </label>
    </div>
  )
})


export default RadioButton