import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoginResponseType, UserType } from '@ec/types'

export interface AuthState {
  authenticated: boolean
  authChecked: boolean,
  twoFactorChallenged: boolean,
  confirmedPassword: boolean
  user?: UserType
}

const initialState: AuthState = {
  authenticated: false,
  authChecked: false,
  twoFactorChallenged: false,
  confirmedPassword: false,
  user: undefined,
}

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<LoginResponseType>) => {
      state.user = action.payload.user
      state.twoFactorChallenged = action.payload.two_factor_challenged
      state.authenticated = true
    },
    loginViaTwoFactorAuthentication: (state, action: PayloadAction<UserType>) => {
      state.user = action.payload
      state.authenticated = true
    },
    loginViaSession: (state, action: PayloadAction<UserType>) => {
      state.user = action.payload
      state.authenticated = true
    },
    logout: (state) => {
      state.user = undefined
      state.authenticated = false
      state.confirmedPassword = false
    },
    // Sets if we have checked for authenticated user on application load
    setAuthChecked: (state, action: PayloadAction<boolean>) => {
      state.authChecked = action.payload
    },
    setConfirmedPassword: (state, action: PayloadAction<boolean>) => {
      state.confirmedPassword = action.payload
    },
    updateAuthUser: (state, action: PayloadAction<UserType>) => {
      state.user = Object.assign(state.user ?? {}, action.payload)
    },
  },
})

export const {
  login,
  loginViaTwoFactorAuthentication,
  loginViaSession,
  logout,
  setAuthChecked,
  setConfirmedPassword,
  updateAuthUser,
} = auth.actions

export default auth.reducer