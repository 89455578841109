import FormError from '../../components/FormError'
import RadioButton from './components/button'

type PropTypes = {
  label: string
  description?: string
  children: Array<JSX.Element>
  error?: string
}

const RadioGroup = ({ 
  label, 
  description, 
  children, 
  error,
}: PropTypes) => {
  return (
    <div>
      <label className="text-sm font-medium text-gray-900">{label}</label>
      {
        description &&
        <p className="text-xs leading-5 text-gray-500">{description}</p>
      }
      <fieldset className="mt-4">
        <legend className="sr-only">{label}</legend>
        <div className="space-y-4 sm:flex flex-col sm:flex-row sm:items-center sm:space-y-0 sm:space-x-10">
          {children}
        </div>
      </fieldset>
      {
        error &&
        <FormError text="error" />
      }
    </div>
  )
}

RadioGroup.Button = RadioButton

export default RadioGroup