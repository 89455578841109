const twitterIcon = ({ className }: { className: string }) => {
  return (
    <svg className={className} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_19_101)">
        <path d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z" fill="#1B9DF0"/>
        <path d="M10.2127 19.0938C15.7564 19.0938 18.7877 14.5 18.7877 10.5188C18.7877 10.3875 18.7877 10.2563 18.7814 10.1313C19.3689 9.70625 19.8814 9.175 20.2877 8.56875C19.7502 8.80625 19.1689 8.96875 18.5564 9.04375C19.1814 8.66875 19.6564 8.08125 19.8814 7.375C19.3002 7.71875 18.6564 7.96875 17.9689 8.10625C17.4189 7.51875 16.6377 7.15625 15.7689 7.15625C14.1064 7.15625 12.7564 8.50625 12.7564 10.1688C12.7564 10.4063 12.7814 10.6375 12.8377 10.8563C10.3314 10.7313 8.1127 9.53125 6.6252 7.70625C6.36895 8.15 6.21895 8.66875 6.21895 9.21875C6.21895 10.2625 6.7502 11.1875 7.5627 11.725C7.06895 11.7125 6.60645 11.575 6.2002 11.35C6.2002 11.3625 6.2002 11.375 6.2002 11.3875C6.2002 12.85 7.2377 14.0625 8.61895 14.3438C8.36895 14.4125 8.1002 14.45 7.8252 14.45C7.63145 14.45 7.44395 14.4313 7.25645 14.3938C7.6377 15.5938 8.7502 16.4625 10.0689 16.4875C9.0377 17.2938 7.7377 17.775 6.3252 17.775C6.08145 17.775 5.84395 17.7625 5.60645 17.7313C6.9252 18.5938 8.5127 19.0938 10.2127 19.0938Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_19_101">
          <rect width="25" height="25" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default twitterIcon
