import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { GET_AUTH_USER } from '@ec/apollo/src/queries/auth'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { useQuery } from '@apollo/client'
import { UserType } from '@ec/types'
import { useEffect } from 'react'
import { loginViaSession, setAuthChecked } from 'slices/auth'
import { LoadingScreen, Toast } from '@ec/ui'
import { useNotifier } from 'react-headless-notifier'

const ProtectedRoute = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { notify } = useNotifier()
  const navigate = useNavigate()

  const { isAuthenticated, authChecked } = useSelector((state: RootState) => ({
    isAuthenticated: state.auth.authenticated,
    authChecked: state.auth.authChecked,
  }))

  const { data, loading, error } = useQuery<{ getAuthUser: UserType }>(GET_AUTH_USER)

  useEffect(() => {
    if (data?.getAuthUser) {
      dispatch(loginViaSession(data.getAuthUser))
    }
    if (data || error) {
      dispatch(setAuthChecked(true))
    }
  }, [data, error])

  useEffect(() => {
    if (authChecked && data?.getAuthUser) {
      if (data?.getAuthUser?.context !== 'EMPLOYER') {
        notify(<Toast type="warning" message="You are not authorised to see this page!" />)
        navigate('/')
      }
    }
  }, [data, authChecked])

  if (loading || !authChecked) {
    return (
      <LoadingScreen />
    )
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  return (
    <div className="flex flex-col h-full w-full mx-auto">
      <Outlet />
    </div>
  )
}

export default ProtectedRoute
