import { ReactNode } from 'react'

type PropTypes = {
  children: ReactNode
  className?: string
}

const Container = ({ children, className = '' }: PropTypes) => {
  return (
    <div className={`lg:max-w-[1200px] w-full px-8 lg:px-2 mx-auto ${className}`}>
      {children}
    </div>
  )
}

export default Container