import { gql } from '@apollo/client'

export const OPPORTUNITY_FIELDS = gql`
  fragment OpportunityFields on Opportunity {
    id
    slug
    title
    content
    excerpt
    skills
    duration
    is_dbs_required
    street_address
    postcode
    status
    city
    county
    country
    latitude
    longitude
    second_line
    capacity
    starts_at
    deadline_at
    ends_at
    cancelled_at
    reason_for_cancellation
    created_at
    updated_at
    deadline_at
    capacity
    manager_instructions
    opportunityTypes {
      id
      name
    }
    images { 
      id
      url
    }
  }
`

export const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    profile_picture {
      id
      url
    }
    profile_banner {
      id
      url
    }
    organisation {
      id
      name
      website
      address
      secondary_address
      city
      county
      postcode
      organisation_image {
        id
        url
      }
    }
    notificationPreferences {
      id
      leave_opportunity
      submission_accepted
      submission_pending
      submission_rejected
      upcoming_opportunities
      opportunity_postponed
      opportunity_feedback
      opportunity_cancelled
      opportunity_at_capacity
      opportunity_deleted
      opportunity_pending
      opportunity_published
      opportunity_rejected
      data_deletion_request_reminder
      pending_opportunities
      data_deletion_request
      data_deletion_complete
      data_deletion_rejected
      personal_data_exported
    }
    context
    name
    email
    date_of_birth
    nickname
    biography
    experience
    credentials
    criminal_history
    has_dbs
    has_drivers_license
    email_verified_at
    two_factor_confirmed
    two_factor_enabled
    two_factor_recovery_codes
    two_factor_svg
    created_at
    updated_at
  }
`

export const ORGANISATION_FIELDS = gql`
  fragment OrganisationFields on Organisation {
    organisation_image {
      id
      url
    }
    id
    name
    website
    address
    secondary_address
    city
    county
    postcode
  }
`