import { ReactNode } from 'react'

const App = ({ children }: { children: ReactNode }) => {
  return (
    <div className="h-full App mx-auto">
      {children}
    </div>
  )
}

export default App
