import classNames from 'classnames'
import { CrossIcon } from '../../icons'

type NotificationProps = {
  title?: string
  message: string
  type?: 'success' | 'info' | 'error' | 'warning'
  dismiss?: () => void
}

const Toast = ({ title, message, type = 'success', dismiss }: NotificationProps) => {

  const notificationClasses = classNames({
    'bg-green-100 border-green-600': type === 'success',
    'bg-blue-100 border-blue-600': type === 'info',
    'bg-red-100 border-red-600': type === 'error',
    'bg-orange-100 border-orange-400': type === 'warning',
  })

  const indicatorClasses = classNames({
    'bg-green-600': type === 'success',
    'bg-blue-600': type === 'info',
    'bg-red-600': type === 'error',
    'bg-orange-400': type === 'warning',
  })

  return (
    <div className={`flex gap-3 border p-[17px] rounded ${notificationClasses}`}>

      <div className={`min-w-[21px] min-h-[20px] max-w-[21px] max-h-[20px] rounded-full ${indicatorClasses}`}></div>

      <div>
        <p className="font-semibold text-notification-title capitalize leading-6">{title ?? type}</p>
        <p className="text-notification-message text-sm leading-5 mt-1">{message}</p>
      </div>

      <div className="relative -mt-1 ml-auto">
        <button
          type="button"
          onClick={dismiss}
        >
          <CrossIcon className="text-black text-opacity-[0.45] w-5 h-5" />
        </button>
      </div>

    </div>
  )
}

export default Toast