import { Fragment } from "react"
import { UserType } from "@ec/types"
import { Menu, Transition } from "@headlessui/react"
import Image from "@ec/ui/src/components/Image"
import { Link } from "react-router-dom"

type PropTypes = {
  user: UserType
  children?: React.ReactNode
  logout: () => void
  links: [LinkType]
}

type LinkType = {
  url: string
  text: string,
  anchorTag?: boolean
}

const profilePicClassNames = "w-[30px] lg:w-[45px] h-[30px] lg:h-[45px] lg:mr-4 rounded-full shadow-sm"

const ProfileDropdown = ({ user, children, links, logout }: PropTypes) => {

  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="flex items-center justify-end ">
          <span className="sr-only">Open user menu</span>
          {user?.profile_picture?.url
            ? <Image source={user?.profile_picture?.url} className={`${profilePicClassNames} object-cover ml-auto`} />
            : <div className={`${profilePicClassNames} bg-primary-blue text-blue-300 font-semibold text-xs lg:text-lg flex items-center`}><p className="text-center w-full">{user.name.split(' ').map(word => word[0]).slice(0, 2)}</p></div>
          }
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-80 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-primary-pink ring-opacity-5 focus:outline-none">
          {
            children &&
            <>
              {children}
              <hr className="my-3" />
            </>
          }
          {
            links.map((link, index) => {
              return (
                <Menu.Item key={`nav-{$index}`}>
                  {({ active }) => (
                    <>
                      {
                        link.anchorTag === true ?
                          <a
                            href={link.url}
                            className={`${active ? 'bg-gray-100 hover-bg-gray-100 text-primary-blue' : 'text-gray'} block px-4 py-2 text-sm`}
                          >
                            {link.text}
                          </a>
                          :
                          <Link
                            to={link.url}
                            className={`${active ? 'bg-gray-100 text-primary-blue' : 'text-gray'} block px-4 py-2 text-sm`}
                          >
                            {link.text}
                          </Link>
                      }
                    </>


                  )}
                </Menu.Item>
              )
            }
            )}
          <Menu.Item as="button" onClick={() => logout()} className="w-full">
            {({ active }) => (
              <span className={`${active ? 'bg-gray-100 text-primary-blue' : 'text-gray'} block px-4 py-2 text-sm w-full text-left`}>
                Sign Out
              </span>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default ProfileDropdown