import { useMutation } from '@apollo/client'
import { FORGOT_PASSWORD } from '@ec/apollo/src/mutations/auth'
import { Button, Input, Toast } from '@ec/ui'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNotifier } from 'react-headless-notifier'

type ForgotPasswordProps = {
  email: string
}

const ForgotPasswordPage = () => {
  const { register, handleSubmit } = useForm<ForgotPasswordProps>()
  const { notify } = useNotifier()

  const [forgotPasswordMutation, { data, loading, error }] = useMutation(FORGOT_PASSWORD)

  const onSubmit: SubmitHandler<ForgotPasswordProps> = (form) => {
    forgotPasswordMutation({
      variables: {
        email: form.email,
      },
    })
  }

  useEffect(() => {
    if (data) {
      notify(<Toast message="If you are registered with us, an email has been sent to your account with the next steps to reset your password" />)
    }
  }, [data])

  useEffect(() => {
    if (error) {
      notify(<Toast title="Error" message="Something went wrong. Please try again later" type="error" />)
    }
  }, [error])

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Recover your account</h2>
          <p className="mt-3 text-gray-500 text-sm mx-auto text-center">Forgotten your password? Input your email and click the button below to be sent a recovery email</p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">

            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <Input
                label="Email"
                {...register('email')}
                type="email"
                required
              />

              <Button
                block
                isLoading={loading}
              >
                Send Recovery Email
              </Button>

            </form>
          </div>
        </div>

      </div>
    </>
  )
}

export default ForgotPasswordPage