import { Opportunity } from '@ec/types'
import ContentCard from '../../../components/Cards/ContentCard'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { regionalAddress } from '../../../helpers/strings/address'

type PropTypes = {
  opportunities?: Opportunity[]
  skeletonCount?: number
  isLoading?: boolean
  initialSlide?: number
  isCentered?: boolean
  onClick?: () => void
}

const FeaturedOpportunitiesSwiper = ({
  isLoading = false,
  skeletonCount = 6,
  opportunities,
  initialSlide = 1,
  isCentered = true,
  onClick,
}: PropTypes) => {
  return (
    <div className="w-full">
      <Swiper
        centeredSlides={isCentered}
        initialSlide={initialSlide}
        spaceBetween={34}
        slidesPerView="auto"
      >
        {
          isLoading
            ? !opportunities && getSkeletonSwiperCards(skeletonCount)
            : opportunities && opportunities.slice(0, 6).map((opportunity) => {
              // Only getting the first 6 for now as there is no way to make opportunities "featured"
              return (
                <SwiperSlide key={`featured-opportunity-${opportunity.id}`} className="lg:max-w-[400px] px-4 lg:px-0" onClick={onClick}>
                  <ContentCard
                    title={opportunity.title}
                    heading={regionalAddress({ city: opportunity.city, county: opportunity.county })}
                    content={opportunity.excerpt}
                    contentMadeAt={opportunity.deadline_at}
                    href={`/opportunities/${opportunity.slug}`}
                    imageSource={opportunity.images[0]?.url}
                    tags={opportunity.opportunityTypes}
                  />
                </SwiperSlide>
              )})
        }
      </Swiper>
    </div>
  )
}

// Skeleton cards for swiper
const getSkeletonSwiperCards = (count: number) => (
  <>
    {
      Array.from(Array(count)).map((index, key) => (
        <SwiperSlide key={`featured-opportunity-skeleton-${key}`} className="lg:max-w-[400px] px-4 lg:px-0">
          <ContentCard isLoading={true} />
        </SwiperSlide>
      ))
    }
  </>
)

export default FeaturedOpportunitiesSwiper