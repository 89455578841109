import { Container, CrossIcon } from '@ec/ui'
import { Disclosure } from '@headlessui/react'
import { ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { HamburgerIcon } from '@ec/ui/src/icons'
import ProfileDropdown from './ProfileDropdown'

/**
 * @url The url which the link goes to
 * @text The friendly text for the url which the user sees
 * @root if the url belongs to a nest of routes which need to be recognised, specify the ROOT route
 */
type LinkType = {
  url: string
  text: string
  root?: string
}

type PropTypes = {
  logo: ReactNode,
  links?: LinkType[],
  actions?: ReactNode,
  persistActions?: boolean
}

const Navbar = ({ logo, links, actions, persistActions = false }: PropTypes) => {
  const location = useLocation().pathname

  return (
    <Disclosure as="nav" className="border-b border-divider mb-5">
      {({ open }) => (
        <>
          <Container>
            <div className="relative flex items-center justify-between h-20">
              <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-primary-blue hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <CrossIcon className="block h-6 w-6" />
                  ) : (
                    <>
                      {
                        links &&
                        <HamburgerIcon className="block h-6 w-6" />
                      }
                    </>
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center md:justify-start h-full">
                <div className="flex flex-shrink-0 items-center">
                  {logo}
                </div>
                <div className="hidden md:ml-10 md:flex h-full">
                  <div className="flex space-x-10">
                    {links && links.map((link, index) => (
                      <div key={`navbar-links-${index}`} className={`h-full flex items-center text-sm transition-all border-b-2 ${(link.root && location.startsWith(link?.root)) || link.url === location ? 'text-primary-dark border-primary-pink' : 'text-placeholder border-transparent'}`}>
                        <Link to={link.url} className="block">
                          {link.text}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className={`${persistActions ? 'flex' : 'hidden'} md:flex items-center md:ml-2 gap-[25px]`}>
                {actions}
              </div>
            </div>
            {
              links &&
              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3">
                  {links.map((link) => (
                    <Disclosure.Button
                      key={link.text}
                      as={Link}
                      to={link.url}
                      className={`px-3 py-2 block first-letter:text-sm transition-all ${link.url === location ? 'text-primary-blue bg-gray-100' : 'text-placeholder'}`}
                    >
                      {link.text}
                    </Disclosure.Button>
                  ))}
                  {
                    (actions && !persistActions) &&
                    <div className="flex flex-col gap-y-3 px-3 border-t border-divider mt-4 pt-4">
                      {actions}
                    </div>
                  }


                </div>
              </Disclosure.Panel>
            }

          </Container>


        </>
      )}
    </Disclosure>
  )
}

Navbar.Profile = ProfileDropdown

export default Navbar