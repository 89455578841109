import { ReactNode } from 'react'

const ModalFooter = ({ children, className }: { children: ReactNode, className?: string }) => {
  return (
    <div className={`${className} pt-8`}>
      {children}
    </div>
  )
}

export default ModalFooter
