import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import ModalHeader from './components/header'
import ModalContent from './components/content'
import ModalFooter from './components/footer'
import classNames from 'classnames'
import { SpinnerIcon, CrossIcon } from '../../icons'

type PropTypes = {
  isOpen: boolean,
  className?: string,
  onClose: () => void,
  afterLeave?: () => void,
  loading?: boolean,
  children?: React.ReactNode
  padMobile?: boolean
  size?: 'small' | 'medium' | 'large'
}

const Modal = ({
  onClose,
  children,
  className,
  loading,
  afterLeave,
  isOpen = false,
  padMobile = false,
  size = 'small',
}: PropTypes): JSX.Element => {

  return (
    <Transition show={isOpen} as={Fragment} afterLeave={afterLeave}>
      <Dialog onClose={onClose} className="relative z-[999]">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className={classNames('fixed inset-0 justify-center w-full md:p-4 flex items-center', {
            'px-2': padMobile,
          })}>
            <Dialog.Panel className={classNames('relative z-20 max-h-full w-full h-full bg-white lg:px-16 px-12 py-14 md:h-min overflow-y-auto rounded-lg', {
              'md:max-w-lg': size === 'small',
              'md:max-w-2xl': size === 'medium',
              'md:max-w-4xl': size === 'large',
            })}>
              {/* Close button */}
              <div className="absolute right-0 p-5 top-6 md:top-0">
                <button className="cursor-pointer outline-indigo-600 focus:shadow-focus-large" onClick={onClose}>
                  <CrossIcon className="w-5 h-5 text text-gray" />
                </button>
              </div>

              {
                loading &&
                <div className="grid h-full place-content-center">
                  <SpinnerIcon className="w-6 h-6" />
                </div>
              }

              {!loading && children}
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}

Modal.Header = ModalHeader
Modal.Content = ModalContent
Modal.Footer = ModalFooter

export default Modal
