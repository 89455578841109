import { ChangeEvent, FocusEventHandler, forwardRef } from 'react'

type PropTypes = {
  name: string
  label: string
  description?: string
  onBlur?: FocusEventHandler
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  defaultChecked?: boolean
  variant? : 'primary' | 'alt'
}

const Checkbox = forwardRef<HTMLInputElement, PropTypes>(function Input({
  name,
  label,
  description,
  onBlur,
  onChange,
  disabled = false,
  defaultChecked = false,
  variant = 'primary'
}, ref) {
  return (
    <div className="relative flex items-start">
      <div className="flex h-5 items-center">
        <input
          id={name}
          name={name}
          defaultChecked={defaultChecked}
          type="checkbox"
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          className={`h-4 w-4 disabled:cursor-not-allowed ${variant === 'alt' && 'accent-primary-pink'} rounded`}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={name} className={`font-medium ${disabled ? 'text-gray-500 cursor-not-allowed' : 'text-gray-700'}`}>
          {label}
        </label>
        {
          description !== undefined &&
          <p id="comments-description" className="text-gray-500 mt-1">
            {description}
          </p>
        }
      </div>
    </div>
  )
})

export default Checkbox