type PropTypes = {
  source?: string
  altText?: string
  width?: number
  height?: number
  className?: string
  onClick?: () => void
}

const Image = ({
  source,
  altText = 'image',
  onClick,
  width,
  height,
  className,
}: PropTypes) => {
  return (
    <>
      {
        source
          ? (
            <img
              src={source}
              alt={altText}
              width={width}
              height={height}
              onClick={onClick}
              className={className}
            />
          ) : <div className={`${className} !bg-muted`} onClick={onClick} />
      }
    </>
  )
}

export default Image