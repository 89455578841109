import { gql } from '@apollo/client'
import { USER_FIELDS } from '../fragments'

export const LOGIN_VOLUNTEER = gql`
  ${USER_FIELDS}
  mutation ($input: LoginVolunteerInput) {
    loginVolunteer(input: $input) {
        user {
          ...UserFields
        }
        two_factor_challenged
    }
  }
`

export const LOGIN_VOLUNTEER_MANAGER = gql`
  ${USER_FIELDS}
  mutation ($input: LoginVolunteerManagerInput) {
    loginVolunteerManager(input: $input) {
      user {
        ...UserFields
        organisation {
          subscription {
            type
            status
            priceInformation {
              monthlyTotal
              seatPrice
            }
          }
          subscriptionLimits {
            opportunityLimit
            volunteerLimit
            opportunityCount
            volunteerCount
          }
        }
      }
      two_factor_challenged
    }
  }
`

export const LOGIN_EMPLOYER = gql`
  ${USER_FIELDS}
  mutation ($input: LoginEmployerInput) {
    loginEmployer(input: $input) {
        user {
          ...UserFields
        }
        two_factor_challenged
    }
  }
`

export const LOGIN_VIA_TWO_FACTOR_AUTHENTICATION = gql`
  ${USER_FIELDS}
  mutation LoginViaTwoFactorAuthenticationMutation($code: String!) {
    loginViaTwoFactorAuthentication(code: $code) {
      ...UserFields
    }
  }
`

export const LOGOUT = gql`
  mutation LogoutMutation {
    logout {
      id
    }
  }
`

export const REGISTER_VOLUNTEER = gql`
  mutation ($input: RegisterVolunteerInput) {
    registerVolunteer(input: $input) {
      user {
        id
        name
        email
        date_of_birth
        context
      }
    }
  }
`

export const REGISTER_VOLUNTEER_MANAGER = gql`
  mutation ($input: RegisterVolunteerManagerInput) {
    registerVolunteerManager(input: $input) {
      user {
        id
        organisation {
          id
          name
        }
        name
        email
        date_of_birth
        context
      }
    }
  }
`

export const REGISTER_EMPLOYER = gql`
  mutation ($input: RegisterEmployerInput) {
    registerEmployer(input: $input) {
      user {
        name
        email
        date_of_birth
        context
      }
    }
  }
`

export const FORGOT_PASSWORD = gql`
  mutation ForgotPasswordMutation ($email: String!) {
    forgotPassword(email: $email)
  }
`

export const SEND_VERIFICATION_EMAIL = gql`
  mutation sendVerifcationEmail {
    sendVerifcationEmail {
      email
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPasswordMutation ($email: String!, $password: String!, $passwordConfirmation: String!, $token: String!) {
    resetPassword(email: $email, password: $password, password_confirmation: $passwordConfirmation, token: $token)
  }
`

export const CONFIRM_PASSWORD = gql`
  mutation ConfirmPasswordMutation ($password: String!) {
    authConfirmPassword(password: $password)
  }
`

export const UPDATE_PASSWORD = gql`
  mutation ($current_password: String!, $new_password: String!, $password_confirmation: String!) {
    updatePassword (current_password: $current_password, new_password: $new_password, password_confirmation: $password_confirmation) 
  }
`

export const ENABLE_TWO_FACTOR = gql`
  mutation EnableTwoFactorAuthentication {
    enableTwoFactorAuthentication {
      id
      name
      email
      two_factor_enabled
      two_factor_confirmed
      two_factor_svg
      two_factor_recovery_codes
    }
  }
`

export const CONFIRM_TWO_FACTOR = gql`
  mutation ConfirmTwoFactorAuthentication ($code: String!) {
    confirmTwoFactorAuthentication(code: $code) {
      id
      name
      email
      two_factor_enabled
      two_factor_confirmed
      two_factor_svg
      two_factor_recovery_codes
    }
  }
`
export const DISABLE_TWO_FACTOR = gql`
  mutation DisableTwoFactorAuthentication {
    disableTwoFactorAuthentication {
      id
      name
      email
      two_factor_enabled
      two_factor_confirmed
      two_factor_svg
      two_factor_recovery_codes
    }
  }
`

export const UPDATE_SELF = gql`
  mutation ($input: UpdateAccountDetailsInput) {
    updateAccountDetails (input: $input) {
      id
      name
      email
      date_of_birth
      profile_picture {
        id
        url
      }
    }
  }
`

export const CREATE_PERSONAL_DATA_EXPORT = gql`
  mutation createPersonalDataExport($id: ID!) {
    createPersonalDataExport(id: $id) {
      id
    }
  }
`

export const CREATE_DATA_DELETION_REQUEST = gql`
  mutation createDataDeletionRequest {
    createDataDeletionRequest {
      id
    }
  }
`