import classNames from 'classnames'
import { SpinnerIcon } from '../../icons'
import { MouseEventHandler, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

type PropTypes = {
  children: ReactNode
  variant?: 'primary' | 'primary-alt' | 'secondary' | 'success' | 'success-alt' | 'danger' | 'primary-gray'
  rounded?: 'rounded-sm' | 'rounded-md' | 'rounded-lg' | 'rounded-xl'
  disabled?: boolean
  block?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  isLoading?: boolean
  type?: 'submit' | 'button' | 'reset'
  href?: string
  newTab?: boolean
  id?: string
  ariaLabel?: string
}

const Button = ({
  children,
  variant = 'primary',
  rounded = 'rounded-md',
  disabled = false,
  block = false,
  onClick,
  isLoading = false,
  type = 'submit',
  href,
  newTab = false,
  id = '',
  ariaLabel = ''
}: PropTypes) => {

  const buttonClassNames = classNames(
    `flex justify-center items-center ${rounded} shadow-sm whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors cursor-pointer`,
    {
      'text-gray-500 bg-gray-200 ring-1 ring-gray-300': disabled,

      'text-white bg-primary-blue hover:bg-secondary-blue focus:ring-primary-blue': variant === 'primary' && !disabled,
      'text-text-gray bg-white hover:bg-border-gray focus:ring-primary-blue rounded-[10px] border font-semibold': variant === 'primary-gray' && !disabled,

      'text-primary-blue bg-white border-[1px] border-primary-blue focus:ring-primary-blue font-semibold': variant === 'primary-alt' && !disabled,

      'border border-gray-300 bg-white hover:bg-gray-20 focus:ring-indigo-500': variant === 'secondary' && !disabled,
      'text-white bg-primary-green hover:bg-secondary-green focus:ring-primary-green': variant === 'success' && !disabled,
      'text-primary-green bg-white border-2 border-primary-green focus:ring-primary-green font-semibold': variant === 'success-alt' && !disabled,
      'text-primary-blue bg-white border-2 border-primary-blue focus:ring-primary-blue font-semibold': variant === 'primary-alt' && !disabled,
      'text-white bg-red-600 hover:bg-red-700 focus:ring-red-500': variant === 'danger' && !disabled,

      'w-full': block,
    },
  )

  const navigate = useNavigate()

  const spinnerVariant = classNames('absolute w-4 h-4', {
    'text-indigo-600': variant === 'secondary', 
    'text-white': variant === 'primary' || variant === 'success' || variant === 'danger', 
  })

  const buttonChildrenContent = <>
    <span className={classNames('px-6 py-3', isLoading && 'invisible', 'leading-5')}>{children}</span>
    <SpinnerIcon className={classNames(isLoading ? spinnerVariant : 'hidden')} />
  </>

  if (href) {
    return (
      <a
        tabIndex={0}
        type={type}
        className={buttonClassNames}
        onClick={() => {
          newTab ? window.open(href) : navigate(href)
        }}
        id={id}
        aria-label={ariaLabel}
      >
        {buttonChildrenContent}
      </a>    
    )
  } else {
    return (        
      <button
        type={type}
        className={buttonClassNames}
        disabled={disabled || isLoading}
        onClick={onClick}
        id={id}
        aria-label={ariaLabel}
      >
        {buttonChildrenContent}
      </button>
    )
  }
}


export default Button