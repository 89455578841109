import SpinnerIcon from '../../icons/spinnerIcon'
import Logo from '../../svgs/logo'

const LoadingScreen = () => {
  return (
    <div className="h-screen flex items-center justify-center">
      <div className="relative -mt-20 flex flex-col justify-center items-center">
        <Logo className="w-full max-w-[18.75rem] mb-10" />
        <SpinnerIcon className="h-6 w-6" />
      </div>
    </div>
  )
}

export default LoadingScreen