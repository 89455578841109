import { useMutation } from '@apollo/client'
import { RESET_PASSWORD } from '@ec/apollo/src/mutations/auth'
import { Button, Input } from '@ec/ui'
import { useEffect, useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { login } from 'slices/auth'

type ResetPasswordProps = {
  email: string
  token: string | null
  password: string
  password_confirmation: string
}

const ResetPasswordPage = () => {
  const search = useLocation().search
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const token = useMemo(() => searchParams.get('token'), [search])

  const { register, handleSubmit, watch } = useForm<ResetPasswordProps>({
    defaultValues: {
      password: '',
      password_confirmation: '',
      token: token,
    },
  })
    
  const doPasswordsMatch = watch('password_confirmation') === watch('password')

  const [resetPasswordMutation, { data: resetData, loading: isResetLoading }] = useMutation(RESET_PASSWORD)

  const onSubmit: SubmitHandler<ResetPasswordProps> = (form) => {
    resetPasswordMutation({ 
      variables: { 
        email: form.email,
        password: form.password,
        passwordConfirmation: form.password_confirmation,
        token: form.token,
      }, 
    })
  }

  useEffect(() => {
    if (resetData?.resetPassword) {
      dispatch(login(resetData?.resetPassword))
      navigate('/login')
    }
  }, [resetData])

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Reset your password</h2>
          <p className="mt-3 text-gray-500 text-sm mx-auto text-center">Confirm your email and new password to recover your account</p>
        </div>
 
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">

            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <Input
                label="Email"
                value={searchParams.get('email') ?? undefined}
                disabled={searchParams.get('email') ? true : false}
                required
                {...register('email')}
              />

              <Input 
                label="Password"
                type="password"
                required
                {...register('password')}
              />
              
              <div>
                <Input 
                  label="Confirm Password"
                  type="password"
                  required
                  {...register('password_confirmation')}
                />
                { 
                  (watch('password').length > 1 && watch('password_confirmation').length > 1) 
                    ? doPasswordsMatch
                      ? <p className="text-green-600 text-sm mt-2">These passwords match</p>
                      : <p className='text-red-600 text-sm mt-2'>These passwords do not match</p>
                    : <></>
                }
              </div>

              <Button 
                block
                isLoading={isResetLoading}
                disabled={!doPasswordsMatch}
              >
                Confirm Change
              </Button>

            </form>
          </div>
        </div>

      </div>
    </>
  )
}

export default ResetPasswordPage