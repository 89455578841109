import { useMutation } from '@apollo/client'
import { LOGOUT } from '@ec/apollo/src/mutations/auth'
import { Toast } from '@ec/ui'
import { useEffect } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from 'slices/auth'
import { RootState } from 'store'
import Navbar from '@ec/ui/src/components/Navbar'
import Logo from '@ec/ui/src/svgs/logo'

const AuthNavbar = () => {
  const { notify } = useNotifier()
  const dispatch = useDispatch()

  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))

  const [logoutMutation, { data: logoutData }] = useMutation(LOGOUT)

  useEffect(() => {
    if (logoutData) {
      notify(<Toast type='info' message='You have signed out successfully' />)
      dispatch(logout())
    }
  }, [logoutData])


  return (
    <Navbar
      logo={
        <Link to="/" className="block max-w-[180px] lg:max-w-[260px] w-full">
          <Logo className="w-full" />
        </Link>
      }
      actions={
        <>
          {user &&
            <Navbar.Profile
              user={user}
              links={[{
                url: '/account/settings',
                text: 'Account Settings',
              }]}
              logout={logoutMutation} />
          }
        </>
      }
      persistActions={true}
    />
  )
}

export default AuthNavbar