import { useMutation } from '@apollo/client'
import { REGISTER_EMPLOYER } from '@ec/apollo/src/mutations/auth'
import { LoginResponseType } from '@ec/types'
import { Button, Input, Toast } from '@ec/ui'
import useErrorHandler from 'hooks/UseErrorHandler'
import { useEffect } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { login } from 'slices/auth'

type RegisterProps = {
  name: string,
  email: string,
  password: string,
  date_of_birth: Date,
} 

const RegisterPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { notify } = useNotifier()
  const errorHandler = useErrorHandler()


  const { register, handleSubmit, setError, formState: { errors } } = useForm<RegisterProps>()

  const [registerMutation, { data: registerData, loading: isRegisterLoading, error: registerError }] = useMutation<{ registerEmployer: LoginResponseType }>(REGISTER_EMPLOYER)

  const onSubmit: SubmitHandler<RegisterProps> = (form) => {
    registerMutation({
      variables: {
        input: {
          name: form.name,
          email: form.email,
          password: form.password,
          date_of_birth: form.date_of_birth,
        },
      },
    })
  }

  useEffect(() => {
    if (registerData) {
      dispatch(login(registerData.registerEmployer))
      navigate('/')
    }
  }, [registerData])

  useEffect(() => {
    if (registerError) {
      notify(<Toast type="error" title="Registration Error" message="There was an issue registering your account, please try again" />)
    }
  }, [registerError])

  useEffect(() => {
    if (registerError) {
      errorHandler(registerError, setError)
    }
  }, [registerError])

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight mx-auto text-gray-900 lg:max-w-xs">Register your Employer account</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">

          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>

            <div className="flex gap-4">
              <Input 
                label="Name"
                required
                {...register('name')}
                error={errors.name?.message}
              />
              <Input
                label="Date of Birth"
                required
                type='date'
                error={errors.date_of_birth?.message}
                {...register('date_of_birth')}
                maxLength={10}
              />
            </div>

            <Input 
              label="Email"
              required
              {...register('email')}
              error={errors.email?.message}
            />

            <Input 
              label="Password"
              type="password"
              required
              {...register('password')}
              error={errors.password?.message}
            />

            <Button block isLoading={isRegisterLoading}>
              Register
            </Button>

          </form>
        </div>
      </div>

    </div>
  )
}

export default RegisterPage