import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'

const useToken = () => {
  const [token, setToken] = useState(Cookies.get('XSRF-TOKEN'))

  useEffect(() => {
    if (!token) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/csrf-cookie`, {
        credentials: 'include',
      }).then(() => {
        setToken(Cookies.get('XSRF-TOKEN'))
      })
    }
  }, [])

  return [token]
}

export default useToken