import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from 'App'
import RegisterPage from 'pages/auth/registerPage'
import LoginPage from 'pages/auth/loginPage'
import PageLayout from 'pages/layout'
import ProtectedRoute from 'ProtectedRoute'
import ForgotPasswordPage from 'pages/auth/forgotPasswordPage'
import ResetPasswordPage from 'pages/auth/resetPasswordPage'

const Router = () => {
  return (
    <BrowserRouter>
      <App>
        <Routes>

          {/* LOGGED IN */}
          <Route element={<ProtectedRoute />} >
            <Route element={<PageLayout />} >
              <Route path="/" element={<></>} />
            </Route>
          </Route>

          {/* NOT LOGGED IN */}
          <Route element={<PageLayout />}>
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
          </Route>

        </Routes>
      </App>
    </BrowserRouter>
  )
}

export default Router