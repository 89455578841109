import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import { ApolloClient, useToken } from '@ec/apollo'
import { store } from 'store'
import { BrowserTracing } from '@sentry/tracing'
import { NotifierContextProvider } from 'react-headless-notifier'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import React from 'react'
import Router from 'router'
import './index.css'
import { LoadingScreen } from '@ec/ui'

const uri = process.env.REACT_APP_GRAPHQL_URI

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
})

const notifierConfig = {
  duration: 5000, // Duration of notification in ms
  position: 'topRight', // Position options: 'top', 'topRight', 'topLeft', 'bottomRight', 'bottomLeft', 'bottom'.
}

const XSRFProvider = () => {
  const [token] = useToken()

  if (!token) {
    return <LoadingScreen />
  }

  const apolloClient = ApolloClient(token, uri)

  return (
    <NotifierContextProvider config={notifierConfig}>
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <Router />
        </ApolloProvider>
      </Provider>
    </NotifierContextProvider>
  )
}

root.render(
  <React.StrictMode>
    <XSRFProvider />
  </React.StrictMode>,
)