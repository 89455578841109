/**
 * ### DateFormat Enums
 * `FullDate` Monday, 31st October 2022, 12:00pm
 * `ShortDate` Mon 31 Oct 12:00pm
 */
 enum DateFormat {
  FullDate = 'dddd, Do MMMM YYYY, h:mma',
  ShortDate = 'ddd D MMM h:mma '
}

export default DateFormat