import { ApolloClient as Client, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'

const ApolloClient = (xsrfCookie?: string, graphQlBaseUri?: string) => {
  const httpLink = createUploadLink({
    uri: graphQlBaseUri,
    credentials: 'include',
  })

  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        'X-XSRF-TOKEN': xsrfCookie,
      },
    }
  })

  const graphQLMerge = (existing, incoming) => {
    let newIncomingData = (incoming?.data ?? [])
      .filter(item => !(existing?.data ?? []).some(existItem => existItem.__ref === item.__ref))

    return {
      ...incoming,
      data: [...(existing?.data ?? []), ...(newIncomingData ?? [])]
    }
  }

  const apolloClient = new Client({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            opportunities: {
              keyArgs: false,
              merge(existing = [], incoming) { return graphQLMerge(existing, incoming) }
            },
          }
        },
        User: {
          fields: {
            opportunities: {
              keyArgs: false,
              merge(existing = [], incoming) { return graphQLMerge(existing, incoming) }
            },
            submissions: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return {...incoming, data: [...(existing?.data ?? []), ...(incoming.data ?? [])],}
            }
            },
          }
        }
      }
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    }
  })

  return apolloClient
}

export default ApolloClient