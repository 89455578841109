import { Dialog } from '@headlessui/react'
import classNames from 'classnames'

const ModalHeader = ({ title, subtitle, className }: { title: string, subtitle?: string, className?: string }): JSX.Element => {
  return (
    <div className={classNames('flex flex-col pt-4 pb-2 md:pt-0 md:pb-6', className)}>
      <Dialog.Title as="h2" className="text-2xl font-bold">
        {title}
      </Dialog.Title>
      { subtitle && <p className='text-lg'>{subtitle}</p>}
    </div>
  )
}

export default ModalHeader
