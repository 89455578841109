import { useMutation } from '@apollo/client'
import { LoginResponseType, RegisterType } from '@ec/types'
import { Button, Input, Modal, Toast } from '@ec/ui'
import { useEffect } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { SubmitHandler, useForm } from 'react-hook-form'
import { REGISTER_VOLUNTEER } from '@ec/apollo/src/mutations/auth'
import { Link, useNavigate } from 'react-router-dom'

type PropTypes = {
  isOpen: boolean
  onClose: () => void
  slug?: string
}

const RegisterModal = ({ isOpen = false, onClose, slug }: PropTypes) => {
  const { register, handleSubmit } = useForm<RegisterType>()
  const { notify } = useNotifier()
  const navigate = useNavigate()
  const [registerMutation, { data: registerData, loading: isRegisterLoading, error: isRegisterError }] = useMutation<{ registerVolunteer: LoginResponseType }>(REGISTER_VOLUNTEER)

  const onSubmit: SubmitHandler<RegisterType> = (form) => {
    registerMutation({
      variables: {
        name: form.name,
        email: form.email,
        password: form.password,
        date_of_birth: form.date_of_birth,
      },
    })
  }

  useEffect(() => {
    if(registerData && !isRegisterError){
      onClose()
      notify(<Toast title="Success" message="Please check your e-mail inbox to verify your account." />)
      navigate(`/opportunities/${slug}`)
    }
  }, [registerData])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header title="Sign up to Engaged Communities" subtitle='Register to volunteer to opportunities'/>
        <Modal.Content>
          <div className="flex flex-col space-y-6">

            <div className="flex gap-4">
              <Input
                label="Name"
                required
                {...register('name')}
              />

              <Input
                label="Date of Birth"
                required
                type="date"
                {...register('date_of_birth')}
                maxLength={10}
              />
            </div>

            <Input
              label="Email"
              required
              {...register('email')}
            />

            <Input
              label="Password"
              type="password"
              required
              {...register('password')}
            />

          </div>
        </Modal.Content>
        <Modal.Footer className="flex flex-col gap-6 text-sm text-center">
          <Button block isLoading={isRegisterLoading}>Sign up</Button>
          <span className="text-primary-blue">Already have an account? <Link to="/login" className="underline">Log in</Link></span>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default RegisterModal